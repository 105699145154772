import React, { useEffect, useState } from "react";
import { Link, graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageLayout from "../components/pageLayout";
import {
  Grid,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { getWindowSize, genHelmet } from "../actions/baseActions";

import theme from "../gatsby-theme-material-ui-top-layout/theme";

const title = "Projects";
const headline =
  "BVA is an award-winning interdisciplinary studio that creates culturally responsive and inclusive architecture, design, installation, and events.";

const aspectRatio = 16 / 9;

const styles = {
  root: {
    paddingTop: "60px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  tileContainer: {
    width: "100%",
    height: "100%",
    "& > div": {
      width: "100%",
      height: "100%",
      // width: 'min-content',
      // height: 'min-content'
    },
  },
  gatsbyImg: {
    width: "100%",
    height: "100%",
  },
  contentSlideWrapper: {
    opacity: 0,
    transition: "0.25s ease-in-out",
    "&:hover": {
      opacity: 1,
    },
    position: "absolute",
    top: 0,
    height: "100%",
    "& > div": {
      maxWidth: "90%",
      "& > *": {
        color: "#fff",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
      },
    },
    "& button": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  contentSlideType: {
    textShadow: "0px 0px 16px #fff, 0px 0px 8px #000",
  },
};

const ProjectOverlay = (props) => {
  const { title, category, slug, featured } = props.project;

  return (
    <Link to={slug}>
      <div>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          sx={styles.contentSlideWrapper}
        >
          <Grid item>
            <Typography
              sx={styles.contentSlideType}
              align="center"
              variant={"h6"}
            >
              {category}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={styles.contentSlideType}
              align="center"
              variant={featured ? "h2" : "h4"}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
};

const getCreatedAt = (projects) => {
  return "";
};

const getUpdateAt = (projects) => {
  return "";
};

const ProjectIndex = (props) => {
  const { nodes } = props.data.allContentfulProject;
  const [columns, setColumns] = useState(1);
  const [rowHeight, setRowHeight] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: getWindowSize().width,
        height: getWindowSize().height,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!windowSize.width || !windowSize.height) return;
    const { values } = theme.breakpoints;
    if (windowSize.width >= values.lg) {
      setColumns(4);
      setRowHeight(windowSize.width / 4 / aspectRatio);
    } else if (windowSize.width >= values.sm) {
      setColumns(2);
      setRowHeight(windowSize.width / 2 / aspectRatio);
    } else {
      setColumns(1);
      setRowHeight(windowSize.width / 1 / aspectRatio);
    }
  }, [windowSize]);

  const projects = nodes
    .filter((a) => !a.unlisted)
    .sort((a, b) => (a.publicationDate > b.publicationDate ? -1 : 1));
  projects.sort((a, b) => (a.featured > b.featured ? -1 : 1));

  const createdAt = getCreatedAt(projects);
  const updatedAt = getUpdateAt(projects);

  return (
    <React.Fragment>
      {genHelmet(
        {
          title,
          headline,
          coverImage: projects[0].coverImage,
          createdAt,
          updatedAt,
        },
        {
          type: "projects",
          siteData: props.data.contentfulPage,
          path: props.path,
        }
      )}
      <PageLayout location={props.location}>
        <Grid direction="column" container sx={styles.root}>
          <Grid item sx={styles.title}>
            {/*<Typography variant="h3">{title}</Typography>*/}
            <Typography variant={smDown ? "h6" : "h5"}>{headline}</Typography>
          </Grid>
          <ImageList rowHeight={rowHeight} gap={24} cols={columns}>
            {projects.map((project) => (
              <ImageListItem
                key={Math.random().toString()}
                cols={project.featured && columns >= 2 ? 2 : 1}
                rows={project.featured && columns >= 2 ? 2 : 1}
              >
                <GatsbyImage
                  image={getImage(project.coverImage.gatsbyImageData)}
                  title={project.coverImage.title}
                  alt={project.coverImage.description}
                  style={styles.gatsbyImg}
                />
                <ProjectOverlay project={project} />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </PageLayout>
    </React.Fragment>
  );
};

export default ProjectIndex;

export const pageQuery = graphql`
  {
    allContentfulProject {
      nodes {
        slug
        headline
        title
        featured
        category
        createdAt
        updatedAt
        unlisted
        publicationDate
        coverImage {
          title
          description
          gatsbyImageData
          banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
          thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
        }
      }
    }
    contentfulPage(slug: { eq: "/" }) {
      slug
      headline
      title
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
    }
  }
`;
